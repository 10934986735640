import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import allUsers from './slices/allUsers';
import allAccounts from './slices/allAccounts';
import allChallenges from './slices/allChallanges';
import allAgencies from './slices/allAgencies';
import allLeads from './slices/allLeads';
import allCalendar from './slices/allCalendar';
import allAnalytics from './slices/allAnalytics';
import accountAnalytics from './slices/accountAnalytics';
import allLeadGoals from './slices/allLeadGoals';
import websiteIdentity from './slices/websiteIdentity';
import allPermissions from './slices/allPermissions';
import Admin from './slices/admin';
import realcc from './slices/realcc';
import analytics from './slices/analytics';
import realccUser from './slices/realccUser';
import winner from './slices/winners';
import store from './slices/store';
import advertisement from './slices/advertisement';
import adType from './slices/adType';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  allUsers: allUsers,
  allAccounts: allAccounts,
  allAgencies: allAgencies,
  allLeads: allLeads,
  allCalendar: allCalendar,
  allChallenges: allChallenges,
  allAnalytics: allAnalytics,
  acountSpecificAnalytics: accountAnalytics,
  allLeadGoals: allLeadGoals,
  websiteIdentity: websiteIdentity,
  allPermissions: allPermissions,
  realcc: realcc,
  realccUser: realccUser,
  admin: Admin,
  analytics: analytics,
  winner: winner,
  store,
  advertisement,
  adType,
});

export { rootPersistConfig, rootReducer };
