/* eslint-disable */

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import AllLeadList from '../pages/AllLeadList';
import DashboardSettingView from '../pages/dashboard/DashboardSettingView';
// import GiftsList from 'src/pages/dashboard/GiftList';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <GuestGuard>
          <DashboardLayout />
        </GuestGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <RealAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'item-store',
          children: [
            { path: 'list', element: <ItemStoreList /> },
            { path: 'create', element: <CreateStoreItem /> },
            { path: ':id/edit', element: <EditItemStore /> },
          ],
        },
        {
          path: 'gift',
          children: [
            { path: 'list', element: <GiftsList /> },
            { path: 'create', element: <CreateGiftItem /> },
            { path: ':id/edit', element: <EditGiftItem /> },
          ],
        },
        {
          path: 'advertisement',
          children: [
            { path: 'list', element: <AdvertisementList /> },
            { path: 'create', element: <CreateAdvertisement /> },
            { path: ':id/edit', element: <EditAdvertisement /> },
          ],
        },
        {
          path: 'challenge',
          children: [
            // { element: <Navigate to="/dashboard/account/profile" replace />, index: true },
            { path: 'list', element: <AccountList /> },
            { path: 'list/:id', element: <ChallangeList /> },
            { path: 'new', element: <AccountCreate /> },
            { path: ':id/edit', element: <AccountCreate /> },
            // { path: 'casting', element: <RealccCastingCall /> },
          ],
        },
        {
          path: 'realcc',
          children: [
            // { element: <Navigate to="/dashboard/account/profile" replace />, index: true },
            { path: 'list', element: <RealccList /> },
            { path: ':id/userlist', element: <RealccUserList /> },
            { path: ':id/pdf', element: <RealccUserListPdf /> },
            { path: 'list/:id', element: <ChallangeList /> },
            { path: 'new', element: <RealccCreate /> },
            { path: ':id/edit', element: <RealccCreate /> },
            // { path: 'casting', element: <RealccCastingCall /> },
            // { path: ':id/createLead', element: <CreateLead /> },
          ],
        },
        {
          path: 'winners',
          children: [
            { path: 'list', element: <WinnersList /> },
            { path: ':id/realccList', element: <WinnersListRealcc /> },
          ],
        },
        {
          path: 'terms',
          children: [{ path: 'condition', element: <TermsCondition /> }],
        },
        {
          path: 'analytics',
          children: [{ path: 'dashboard', element: <RealAnalytics /> }],
        },
        {
          path: 'admin',
          children: [
            // { element: <Navigate to="/dashboard/account/profile" replace />, index: true },
            { path: 'list', element: <AccountList /> },
            { path: 'list/:id', element: <ChallangeList /> },
            { path: 'new', element: <AdminCreate /> },
            { path: ':id/edit', element: <AdminCreate /> },
          ],
        },
        ,
        {
          path: 'lead',
          children: [
            { element: <Navigate to="/dashboard/lead/profile" replace />, index: true },
            { path: 'list', element: <LeadList /> },
            { path: 'new', element: <LeadCreate /> },
            { path: ':id/edit', element: <AccountCreate /> },
            { path: ':id/createLead', element: <CreateLead /> },
          ],
        },
        {
          path: 'dashboard',
          children: [
            { element: <Navigate to="/dashboard/analytics" replace />, index: true },
            { path: 'view', element: <AnalyticsView /> },
            { path: ':id/view', element: <AnalyticsView /> },
          ],
        },
        // {
        //   path: 'analytics',
        //   children: [
        //     { element: <Navigate to="/dashboard/analytics" replace />, index: true },

        //     { path: 'view', element: <AnalyticsView /> },
        //     { path: 'agents', element: <AnalyticsView /> },
        //     { path: 'accounts', element: <AccountAnalyticsView /> },
        //     { path: ':id/view', element: <AnalyticsView /> },
        //   ],
        // },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'DashboardSettings', element: <DashboardSettingView /> },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    // {
    //   path: '*',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: 'LeadList', element: <AllLeadList /> },
    //     { path: 'coming-soon', element: <ComingSoon /> },
    //     { path: 'maintenance', element: <Maintenance /> },
    //     { path: 'pricing', element: <Pricing /> },
    //     { path: 'payment', element: <Payment /> },
    //     { path: '500', element: <Page500 /> },
    //     { path: '404', element: <Page404 /> },
    //     { path: '403', element: <Page403 /> },
    //     // { path: '*', element: <Navigate to="/404" replace /> },
    //   ],
    // },
    {
      path: '/',
      // element: <MainLayout />,
      children: [
        {
          // element: <HomePage />,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
        {
          path: 'client-dashboard/:id/view',
          element: <ClientDashboard />,
        },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: 'clientDashboard',
      children: [
        { element: <Navigate to="/dashboard/clientDashboard" replace />, index: true },
        { path: 'view', element: <ClientDashboard /> },
        // { path: ':id/view', element: <LeadList /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// ACCOUNT
const AccountList = Loadable(lazy(() => import('../pages/dashboard/AccountList')));
const ChallangeList = Loadable(lazy(() => import('../pages/dashboard/ChallengeList')));
const AccountCreate = Loadable(lazy(() => import('../pages/dashboard/AccountCreate')));
const CreateLead = Loadable(lazy(() => import('../pages/dashboard/CreateLead')));

// real cc
const RealccList = Loadable(lazy(() => import('../pages/dashboard/RealccList')));
const RealccUserList = Loadable(lazy(() => import('../pages/dashboard/RealccUserList')));
const RealccUserListPdf = Loadable(lazy(() => import('../pages/dashboard/RealUserPdf')));
// casting cal

// const RealccCastingCall = Loadable(lazy(() => import('../pages/dashboard/MobileViewCasting')));

const RealccChallengeList = Loadable(lazy(() => import('../pages/dashboard/ChallengeList')));
const RealccCreate = Loadable(lazy(() => import('../pages/dashboard/RealccCreate')));
const RealccLead = Loadable(lazy(() => import('../pages/dashboard/CreateLead')));

// winner--
const WinnersListRealcc = Loadable(lazy(() => import('../pages/dashboard/WinnersTable')));
const WinnersList = Loadable(lazy(() => import('../pages/dashboard/WinnersList')));

// terms
const TermsCondition = Loadable(lazy(() => import('../pages/dashboard/TermsCondition')));

// analytics --
const RealAnalytics = Loadable(lazy(() => import('../pages/dashboard/RealAnalytics')));

// Admin
const AdminCreate = Loadable(lazy(() => import('../pages/dashboard/AdminCreate')));
// LEAD
const LeadList = Loadable(lazy(() => import('../pages/dashboard/LeadList')));
const ClientDashboard = Loadable(lazy(() => import('../pages/dashboard/ClientDashboard')));
const LeadCreate = Loadable(lazy(() => import('../pages/dashboard/LeadCreate')));

const AnalyticsView = Loadable(lazy(() => import('../pages/dashboard/AnalyticsView')));
const AccountAnalyticsView = Loadable(lazy(() => import('../pages/dashboard/AccountAnalyticsView')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// ITEM STORE
const ItemStoreList = Loadable(lazy(() => import('../pages/dashboard/ItemStoreList')));
const CreateStoreItem = Loadable(lazy(() => import('../pages/dashboard/ItemStoreCreate')));
const EditItemStore = Loadable(lazy(() => import('../pages/dashboard/ItemSToreEdit')));

// ITEM STORE
const GiftsList = Loadable(lazy(() => import('../pages/dashboard/GiftList')));
const CreateGiftItem = Loadable(lazy(() => import('../pages/dashboard/GiftCreate')));
const EditGiftItem = Loadable(lazy(() => import('../pages/dashboard/GiftUpdate')));

//ADVERTISEMENT
const AdvertisementList = Loadable(lazy(() => import('../pages/dashboard/advertisement/AdvertisementList')));
const CreateAdvertisement = Loadable(lazy(() => import('../pages/dashboard/advertisement/AdsCreate')));
const EditAdvertisement = Loadable(lazy(() => import('../pages/dashboard/advertisement/AdsUpdate')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
